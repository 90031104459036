<template>
  <div class="home">
    <top title="惠医保主页" />
    <div class="home_box">
      <div class="home_top">
        <div class="home_top_left">
          <img src="../../assets/img/logo@3x.png" alt="" />
          <div class="home_content">
            <div class="name">惠医保</div>
            <div>直付药房服务卡</div>
          </div>
        </div>
        <div class="card" @click="handleClick">卡片管理</div>
      </div>
      <div class="price_text">
        <div style="font-size: 14px">总余额</div>
        <div class="price">¥ {{ totalBalance }}</div>
      </div>
    </div>
    <div class="item_box slices">
      <wx-open-launch-weapp
        id="launch-btn"
        username="gh_d474d79752f5"
        :path="miniAppType"
      >
        <script type="text/wxtag-template">
          <style>
            .btn{
              font-size:14px;
              color:#333;
              padding:17px 12px;
              width:100%;
            }
          </style>

          <!-- <template>  -->
              <div class="btn">线上商城</div>
          <!-- </template> -->
        </script>
      </wx-open-launch-weapp>
    </div>
    <div class="item_box">
      <div class="list_item" @click="$router.push({ path: '/qrcode' })">
        线下直付 <van-icon name="arrow" color="#888" />
      </div>
      <div class="list_item" v-show="!locationType">
        适用门店
        <div class="item_content" @click="$router.push({ path: '/store' })">
          离你最近{{ kmStr || "0" }}KM
          <van-icon name="arrow" color="#888" style="margin-left: 8px" />
        </div>
      </div>
      <div class="list_item" v-show="locationType">
        适用门店
        <div class="item_content">
          请先打开定位功能<van-icon
            name="arrow"
            color="#888"
            style="margin-left: 8px"
          />
        </div>
      </div>
      <div class="list_item">
        客服电话
        <div class="item_content">
          <a href="tel:020-88525832">020-88525832</a>
          <van-icon name="arrow" color="#888" style="margin-left: 8px" />
        </div>
      </div>
      <div class="list_item" @click="$router.push({ path: '/mine' })">
        我的 <van-icon name="arrow" color="#888" />
      </div>
      <div class="list_item" @click="onClickExit()">
        退出 <van-icon name="arrow" color="#888" />
      </div>
    </div>
  </div>
</template>

<script src="https://res.wx.qq.com/open/js/jweixin-1.6.0.js"></script>
<script>
import { homePage, drugStoreOne, configs } from "@/api/_api/home";
import BMapSTATUSSUCCESS from "BMAP_STATUS_SUCCESS";
import cookies from "vue-cookies";
export default {
  data() {
    return {
      totalBalance: "", //总余额
      kmStr: "", //距离当前位置
      locationType: false,
      miniAppType:''
    };
  },
  methods: {
    onClickTo() {
      let token = `Bearer${cookies.get("token")}`;
      let url = `https://m.360kad.com?token=${token}&platform=hyb`;
      window.open(url);
    },
    handleClick() {
      this.$router.push("/warranty_manage");
    },
    init() {
      homePage().then((res) => {
        if (res.code == 200) {
          this.totalBalance = res.data.totalBalance;
        }
      });
    },
    //获取定位
    getlocation() {
      new Promise((resolve, reject) => {
        let self = this;
        //调用百度API获取
        var geolocation = new BMap.Geolocation();
        geolocation.getCurrentPosition(
           function (position) {
             if (this.getStatus() == BMapSTATUSSUCCESS) {
              if (position.accuracy == null) {
                // self.$toast.success('11111' + position.accuracy)
                self.locationType = true;
                localStorage.setItem('store','0')
               // setTimeout(()=>{
               //     self.$toast({
               //        message:'请确保手机定位已开启'
               //      })
               //  },5000)
              } else {
                // self.$toast.success('222221')
                localStorage.setItem('store','1')
                sessionStorage.setItem("lat", position.point.lat); //纬度
                sessionStorage.setItem("lng", position.point.lng); //经度
                resolve({ lat: position.point.lat, lng: position.point.lng });
              }
            } else {
              // self.$toast.success(33333);
              //用户允许地理授权但不成功获取
              alert("failed" + this.getStatus());
            }
          },
          0,
          { enableHighAccuracy: true }
        );
      }).then((resp) => {
        //距离最近位置
        drugStoreOne({ locationY: resp.lat, locationX:  resp.lng }).then(
          (res) => {
            if (res.code == 200) {
              this.kmStr = res.data.diatance;
            }
          }
        );
      });
    },
    onClickExit() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "是否退出当前登录账户",
        })
        .then(() => {
          localStorage.removeItem("user_data");
          cookies.remove("token");
          this.$router.push({ path: "/" });
        });
    },
    getConfig() {
      return new Promise((resolve) => {
      //   let url = location.href.split('#')[0].replace(/s/,'')
      //   url = encodeURIComponent(url)
        let url = encodeURIComponent(location.href.split('#')[0]);
        configs({ url }).then((res) => {
          if (res.code == 200) {
            resolve(res.data);
          }
        });
      });
    },
  },
  created() {
    let token = `${cookies.get("token")}`;
    this.miniAppType = `/pages/index/index?storeid=31&storename=惠医保直付药房&token=${token}.html`

    this.getConfig().then((res) => {
      const data = res;
      wx.config({
        debug: false,
        appId: "wxd7f1c8ad7bd591cc",
        timestamp: data.timeStamp,
        nonceStr: data.randomStr,
        signature: data.signature,
        jsApiList: ["openLocation", "getLocation"],
        openTagList: ["wx-open-launch-weapp"],
      });
      wx.ready(function (res) {
        console.log(res)
      });
      wx.error(function ({res}) {
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名
        console.log(res);
      });
    });
  },
  mounted() {
    this.getlocation();
    this.init();
    var btn = document.getElementById("launch-btn");
    btn.addEventListener("launch", function (e) {
      console.log("success");
    });
    btn.addEventListener("error", function (e) {
      alert("小程序打开失败");
    });
  },
};
</script>
<style>
#launch-btn{
  display: flex;
}
</style>

<style scoped lang="scss">
.slices {
  margin-bottom: vw(28) !important;
}
.item_box {
  background: #fff;
  margin: 0 vw(24);
  box-shadow: 0px 0px 10px 2px rgba(133, 133, 133, 0.12);
  border-radius: vw(8);
}
.home {
  background: #f9f9f9;
  min-height: 100vh;
}
.home_box {
  background-image: url("../../assets/img/bgOne.png");
  background-size: 100%;
  width: vw(702);
  height: vw(318);
  border-radius: vw(16);
  margin: vw(28) vw(24);
  box-sizing: border-box;
  overflow: hidden;
  box-shadow: 0px 0px 10px 2px rgba(133, 133, 133, 0.12);
}
.cut {
  height: vw(22);
  width: 100%;
  background: #f9f9f9;
}
.home_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: vw(56) 0 vw(26) vw(40);
}
.price_text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  display: flex;
  padding: vw(32) vw(48);
  margin-top: vw(40);
  margin-left: -1px;
  border-bottom-left-radius: vw(16);
  border-bottom-right-radius: vw(16);
  box-shadow: 0px 0px 10px 2px rgba(133, 133, 133, 0.12);
  // color: #fff;
  // font-size: vw(28);
}
.price {
  color: #019cf4;
}
.home_top_left {
  display: flex;
  align-items: center;
  img {
    width: vw(96);
    height: vw(96);
    border-radius: 50%;
    margin-right: vw(28);
  }
  .home_content {
    font-size: vw(28);
    color: #fff;
  }
  .name {
    font-size: vw(32);
    font-weight: 600;
    margin-bottom: vw(12);
  }
}
.card {
  font-size: vw(28);
  color: #fff;
  font-weight: 500;
  padding: vw(4) vw(20);
  letter-spacing: -0.34px;
  background: linear-gradient(90deg, #ffd425 0%, #ff9d00 100%);
  border-top-left-radius: vw(50);
  border-bottom-left-radius: vw(50);
}

.list_item {
  font-size: vw(28);
  color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: vw(34) 0;
  margin: 0 vw(24);
  background: #fff;
  border-bottom: 1px solid #f4f4f4;
}
.item_content {
  display: flex;
  align-items: center;
}
.home_img {
  width: vw(702);
  height: vw(168);
  margin: 0 vw(24);
}
</style>
